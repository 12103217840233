<script setup lang="ts">
import type { BonusesResponse } from "@/types";

const props = defineProps<{
	data: BonusesResponse;
	wheelDurationLeft: string;
	locationTid?: string;
}>();

const { t } = useT();
const { open } = useNlcModals();
const loginGuard = useLoginGuard();

const timerBtn = computed(() => props.data.dailyWheel?.visible && props.data.dailyWheel.reasonType === "time");

const handleDailyWheelClick = () =>
	loginGuard({
		success: () => {
			if (!timerBtn.value) {
				open("LazyOModalDailyWheel");

				dispatchGAEvent({
					event: "click_button",
					button_name: "dailywheel",
					location: "reward_cabinet"
				});
			}
		},
		fail: () => {
			open("LazyOModalSignup");
		}
	});
</script>
<template>
	<div @click="handleDailyWheelClick">
		<div class="icon">
			<NuxtImg
				src="nuxt-img/rewards/daily-wheel.png"
				loading="lazy"
				width="120"
				height="120"
				quality="100"
				format="webp"
				alt="daily wheel"
			/>
		</div>

		<div class="content">
			<AText class="text-cannes" variant="toledo" :modifiers="['bold']" as="div">
				{{ t("Lucky Wheel") }}
			</AText>
			<AText class="text-caracas" :modifiers="['normal']" as="div">
				{{ t("Spin every 12 hours") }}
			</AText>
		</div>
		<AButton
			:variant="timerBtn ? 'secondary' : 'primary'"
			:modifiers="[timerBtn ? 'disabled' : '']"
			:data-tid="locationTid ? `reward-btn-${locationTid}` : null"
			class="btn"
			size="s"
		>
			<AText variant="topeka" :modifiers="['uppercase']">{{ timerBtn ? wheelDurationLeft : "Spin" }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 52px;
	}
}

.btn {
	--a-button-secondary-background: var(--chaozhou);
	--a-button-secondary-disabled-background: var(--chaozhou);
	--a-button-secondary-disabled-color: var(--cannes);
}
</style>
